.feedbacks {
    --slide-spacing: 4px;
    --slide-size: 100%;
    @media screen and (max-width: 460px) {
        --slide-height: 33rem;
    }
    @media screen and (min-width: 450px) and (max-width: 520px) {
        --slide-height: 28rem;
    }
    --slide-height: 20rem;

    &__container {
        backface-visibility: hidden;
        display: flex;
        touch-action: pan-x;
        margin-top: calc(var(--slide-spacing) * -1);
        height: calc(var(--slide-spacing) + var(--slide-height));
        flex-direction: column;
    }

    &__slide {
        flex: 0 0 var(--slide-size);
        min-width: 0;
        padding-left: var(--slide-spacing);
        position: relative;
        height: fit-content;
        max-height: fit-content;
    }

    &-thumbs {
        --thumbs-slide-spacing: 0.8rem;
        /*--thumbs-slide-height: 9rem;*/
    }

    &-thumbs__slide {
        flex: 0 0 28%;
        min-width: 0;
        position: relative;
        @media (min-width: 576px) {
            flex: 0 0 18%;
        }
    }


    &-thumbs__slide__button {
        -webkit-appearance: none;
        background-color: transparent;
        touch-action: manipulation;
        display: block;
        text-decoration: none;
        cursor: pointer;
        border: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        opacity: 0.2;
        transition: opacity 0.2s;
    }

    &-thumbs__slide--selected .feedbacks-thumbs__slide__button {
        opacity: 1;
    }

    &__progress > svg {
        width: 32px;
        height: 32px;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    &__progress > svg circle {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 4px;
        stroke: white;
        fill: none;
        animation: countdown 4s linear infinite forwards;
    }
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }
    to {
        stroke-dashoffset: 113px;
    }
}