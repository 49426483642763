.embla {
    --slide-spacing: 2.93rem;
    --slide-size: 20%;
    --slide-height: 22.5rem;
    /*padding: 1.6rem;*/

    /*--slide-spacing: 1rem;*/
    /*--slide-size: 14%;*/
    /*--slide-height: 19rem;*/
    /*padding: 1.6rem;*/

    &__viewport {
        overflow: hidden;
    }

    &__container {
        backface-visibility: hidden;
        display: flex;
        touch-action: pan-y;
        margin-left: calc(var(--slide-spacing) * -1);
    }

    &__slide {
        @media screen and (min-width: 1024px) {
            flex: 0 0 247px;
        }
        flex: 0 0 235px;
        min-width: 0;
        padding-left: var(--slide-spacing);
        position: relative;
    }

    &__slide__img {
        display: block;
        height: 16.25rem;
        width: 100%;
        object-fit: cover;
    }

    &__slide__number {
        width: 4.6rem;
        height: 4.6rem;
        z-index: 1;
        position: absolute;
        top: 0.6rem;
        right: 0.6rem;
        border-radius: 50%;
        background-color: rgba(var(--background-site-rgb-value), 0.85);
        line-height: 4.6rem;
        font-weight: 900;
        text-align: center;
        pointer-events: none;
    }

    &__slide__number > span {
        color: var(--brand-primary);
        background-image: linear-gradient(
                45deg,
                var(--brand-primary),
                var(--brand-secondary)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.6rem;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__button {
        -webkit-appearance: none;
        touch-action: manipulation;
        display: inline-flex;
        text-decoration: none;
    }

    &__button:disabled {
        opacity: 0.3;
    }
}